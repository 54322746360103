import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ModelType } from '../types/model.types';
import { Mentor } from '../types/mentor.types';
@Injectable({
  providedIn: 'root',
})
export class MentorService {
  constructor(private http: HttpClient) {}

  public getMentorOfUser() {
    return this.http.get<Mentor>(environment.apiUrl + '/mentors/of_user/');
  }

  public updateMentor(mentorId: string, updatedMentor: Partial<ModelType>) {
    return this.http.patch<Mentor>(environment.apiUrl + '/mentors/' + mentorId + '/', updatedMentor);
  }

  public getAllMentors(params: HttpParams) {
    return this.http.get<Mentor[]>(environment.apiUrl + '/mentors/', { params: params });
  }
}
